import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from '../components/layout';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import stringUseAsync from '../utils/stringUseAsync';
import { Helmet } from 'react-helmet';
import { stringUseSafeDispatch, stringUseSafeDispatch2 } from '../utils/stringUseSafeDispatch';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout location={{
      pathname: '/useAsync'
    }} mdxType="Layout">
  <Helmet title={'Avoid Async Memory Leaks With This useSafeDispatch Hook'} mdxType="Helmet" />
  <div className="blog-layout">
    <h1>Avoid Async Memory Leaks With This useSafeDispatch Hook</h1>
    <p>
      I found out recently that one great way to create a memory leak in React
      is by trying to manage state on a component that has been unmounted.{' '}
    </p>
    <aside>
      One solution (that I've seen some claim to be the best) involves having an{' '}
      <a href="https://developer.mozilla.org/en-US/docs/Web/API/AbortController" target="_blank">
        abortController
      </a>{' '}
      to abort your fetch if the component unmounts. If you haven't read up on
      aborting HTTP signals it's worth doing, but I wanted a simpler
      solution.{' '}
    </aside>
    <p>
      What if we could do this without having to much with our http requests? I
      found this great little hook from the legendary{' '}
      <a href="https://kentcdodds.com" target="_blank">
        Kent C. Dodds
      </a>{' '}
      to wrap your dispatch function and do just that.
    </p>
    <SyntaxHighlighter language="jsx" style={materialDark} customStyle={{
          fontFamily: 'Dank Mono',
          margin: '45px 0'
        }} mdxType="SyntaxHighlighter">
      {stringUseSafeDispatch}
    </SyntaxHighlighter>
    <p>
      It's simple enough, we start by creating a ref and initialize it to false.
    </p>
    <p>
      Then, using React.useLayoutEffect with an empty dependency array, we set
      that ref to true when the component mounts (but before it renders - read
      more{' '}
      <a href="https://kentcdodds.com/blog/useeffect-vs-uselayouteffect" target="_blank">
        here
      </a>{' '}
      for the differences between useEffect and useLayoutEffect).
    </p>
    <p>
      The return function on useEffect and useLayoutEffect (with an empty
      dependency array) will run when the component unmounts, and the ref will
      be set back to false.
      <br />
      <br />
      <img style={{
            width: 400,
            maxWidth: '90%'
          }} src="https://media.giphy.com/media/26ufdipQqU2lhNA4g/giphy.gif" />
    </p>
    <p>
      <strong>Putting it all together:</strong>
    </p>
    <p>
      Now we have a ref that tells us in real time whether the component is
      mounted or not, and our hook receives the dispatch function as an
      argument. We just need to give them back a new version of the dispatch
      function that doesn't run if the component is unmounted (we don't care if
      the fetch finishes, as long as we don't try to setState or dispatch).
    </p>
    <SyntaxHighlighter language="jsx" style={materialDark} customStyle={{
          fontFamily: 'Dank Mono',
          margin: '45px 0'
        }} mdxType="SyntaxHighlighter">
      {stringUseSafeDispatch2}
    </SyntaxHighlighter>
    <p>
      Here we spread the args from the dispatch function into our new dispatch
      function that returns void if our ref indicates that the component isn't
      mounted. Finally, we wrap it all in a useCallback so that people using our
      code can use it as a dependency.
    </p>
    <aside>
      <strong>
        Will I really need this?
        <br />
        <br />
      </strong>
      Maybe, maybe not. Server state management libraries like React Query are already
      doing this behind the scenes, so you won't actually have to implement it if
      you're using a prebuilt solution. Either way, I think it's a potential pitfall
      that is useful to know about.
    </aside>
  </div>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      